$green: #469f38;
$dark-green: #1a4f20;
$blue: #35a4cd;
$beige: #e2efb3;
$yellow: gold;
$orange: #ff4500;
$brown: #6e260e;
$dark-chocolate: #1e2019;

$section-title-font-size: 128px;
$section-title-font-size-tablet: 96px;
$section-title-font-size-mobile: 72px;

$standard-border-radius: 4%;

@function titleBg($color) {
  @return linear-gradient(
    90deg,
    rgba($color, 0.5) 0%,
    rgba($color, 1) 50%,
    rgba($color, 0.5) 100%
  );
}
