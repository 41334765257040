@import "/node_modules/normalize.css/normalize.css";
@import "constants";

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: $dark-chocolate;
}

h1,
h2,
h3,
h4 p,
button {
  font-weight: normal;
}

h1,
button,
select {
  font-family: "Londrina Solid", cursive;
}

p,
figcaption {
  font-family: "Varela Round", sans-serif;
}

h2,
h3,
h4 {
  font-family: "Londrina Shadow", cursive;
}

figure {
  margin: unset;
}

button {
  align-self: center;
  background-color: white;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba($brown, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  cursor: url("/src/assets/images/cursor-pointer.png"), auto;
  display: inline-block;
  line-height: 24px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: 0.5s all;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-color: black;

  &:hover {
    box-shadow: rgba($brown, 0.3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    box-shadow: rgba($brown, 0.3) 2px 8px 4px -6px;
  }
}

.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.web3modal-provider-name {
  font-family: "Londrina Shadow", cursive;
  letter-spacing: 1px;
}

.web3modal-provider-description {
  font-family: "Varela Round", sans-serif;
}

[hidden] {
  display: none !important;
}

::selection {
  background-color: $blue;
  color: white;
}
