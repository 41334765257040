html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, span {
  color: #1e2019;
}

h1, h2, h3, h4 p, button {
  font-weight: normal;
}

h1, button, select {
  font-family: Londrina Solid, cursive;
}

p, figcaption {
  font-family: Varela Round, sans-serif;
}

h2, h3, h4 {
  font-family: Londrina Shadow, cursive;
}

figure {
  margin: unset;
}

button {
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  box-sizing: border-box;
  cursor: url("cursor-pointer.2fbfb758.png"), auto;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat-x;
  background-size: 4px 3px;
  border: 1px solid #000;
  border-radius: 255px 15px 15px 225px / 15px 225px 255px 15px;
  outline: none;
  align-self: center;
  padding: .75rem;
  line-height: 24px;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
  box-shadow: 15px 28px 25px -18px #6e260e33;
}

button:hover {
  transform: translate3d(0, 2px, 0);
  box-shadow: 2px 8px 8px -5px #6e260e4d;
}

button:focus {
  box-shadow: 2px 8px 4px -6px #6e260e4d;
}

.web3modal-modal-lightbox {
  z-index: 100 !important;
}

.web3modal-provider-name {
  letter-spacing: 1px;
  font-family: Londrina Shadow, cursive;
}

.web3modal-provider-description {
  font-family: Varela Round, sans-serif;
}

[hidden] {
  display: none !important;
}

::selection {
  color: #fff;
  background-color: #35a4cd;
}

/*# sourceMappingURL=index.b04d7edb.css.map */
